.rulesHeader {
    margin-bottom: 1.5rem;
}

.rule {
    margin-bottom: 1rem;
}
.ruleContent {
    font-size: 1.2rem;
    white-space: pre-wrap;
}

.correct {
    margin-top: 1rem;
    color: var(--green);
}
.incorrect {
    color: var(--red);
}

.root p {
    margin: 0.5rem 0;
}
.root h2 {
    margin-top: 1rem;
}
