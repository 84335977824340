.buttonWrap {
    padding: 0.75rem 0.5rem;
    position: relative;
    cursor: pointer;
}
.buttonWrap.noMr {
    padding-right: 0;
}
.button {
    width: 1rem;
    vertical-align: bottom;
}
