.comment {
    margin-bottom: 1.5rem;
    display: flex;
}
.comment.best {
    border-top: 1px solid var(--background-element);
    border-top-style: dotted;
    padding: 0.75rem;
    margin-bottom: 1rem;
}
.comment.full {
    background: var(--background-secondary);
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.comment.shitty {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
}

.left {
    flex-shrink: 0;
}
.right {
    flex-grow: 1;
}

@media screen and (max-width: 1005px) {
    .comment {
        padding: 0.75rem;
        margin-bottom: 0;
    }
    .comment.full {
        margin-bottom: 1rem;
    }
}

.header {
    display: flex;
    align-items: center;
}
.avatar {
    margin-right: 0.5rem;
}
.author {
    font-weight: bold;
    color: var(--text);
    text-decoration: none;
}
.author:hover {
    text-decoration: underline;
}
.time {
    color: var(--text-secondary);
}
.openPost {
    text-decoration: none;
    color: var(--text);
    margin-left: auto;
}
.openPost:hover, .openPost:focus {
    text-decoration: underline;
}

.content {
    padding: 0.5rem 0;
    word-wrap: anywhere;
}

.quote {
    border-left: 0.25rem solid var(--background-element);
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    word-wrap: anywhere;
}

.images {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem; /* yeah who cares */
    margin: 0.5rem 0;
    border-radius: 0.5rem;
}
.image {
    max-width: 256px;
    max-height: 256px;
}
.image img {
    border-radius: 0.5rem;
}

.footer {
    display: flex;
    align-items: center;
}
.footerReply {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
    cursor: pointer;
}
.footerReply > svg {
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
    vertical-align: bottom;
}

.moreDropdown {
    margin-left: auto;
}
.moreButton {
    margin-left: 0.5rem;
    cursor: pointer;
}
.moreButton > svg {
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: bottom;
}

/* --- editor --- */

.editor {
    margin-bottom: 1rem;
}
@media screen and (max-width: 1005px) {
    .editor {
        padding: 0.75rem;
        margin-bottom: 0;
    }
}

.reply {
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 0.5rem;
    border-left: 0.3rem solid var(--background-element);
}

.editor .input {
    background: var(--background-secondary);
}

.editorFooter {
    display: flex;
    justify-content: flex-end;
}
.attachmentButton {
    width: 1rem;
    cursor: pointer;
    margin-right: 0.5rem;
}
.postButton {
    margin-left: 0.5rem;
}
.postButton > svg {
    margin-right: 0.2rem;
    width: 1rem;
    height: 1rem;
}

.keyboardOnly {
    display: none;
    margin-left: 0.25rem;
    color: var(--text-secondary);
}

@media (hover: hover) and (pointer: fine) {
    .keyboardOnly {
        display: inline;
    }
}
